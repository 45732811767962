import axios from "@axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    
    AcceptService(id,payload) {
      return new Promise((resolve, reject) => {
      //console.log(payload)
      //console.log(id)
        axios
          .put(`/api/v1/person_service_refferals/${payload.id}/approve`,payload)
          .then(response => 
           
            resolve(response)
            )
          .catch(error => reject(error))
      })
     
    },
    replyComplains(ctx,data) {
      return new Promise((resolve, reject) => {
      
        axios
          .post(`/api/v1/complains`,data)
          .then(response => 
           
            resolve(response)
            )
          .catch(error => reject(error))
      })
     
    },
    fetchListProblems(ctx,queryParams) {
    return new Promise((resolve, reject) => {
       
  
      axios
        .get("/api/v1/complains",{ params: queryParams })
        .then((response) => {
         
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  fetchexcel(ctx,queryParams) {
      return new Promise((resolve, reject) => {
  
        axios
          .get("/api/v1/service_maintainer/export_filtered_persons_with_services", { params: queryParams })
          .then((response) => {
           
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchBenServices(ctx,queryParams) {
      return new Promise((resolve, reject) => {
       
        // let url=`/api/v1/service_maintainer/persons?gender=${queryParams.gender}&min_service_registration_date=${queryParams.minDate}&max_service_registration_date=${queryParams.maxDate}`
       //console.log(queryParams)
        axios
          .get("/api/v1/service_maintainer/persons", { params: queryParams })
          .then((response) => {
           
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    OpenStatus(ctx,{id}) {
      return new Promise((resolve, reject) => {
 
  
      let url=`/api/v1/service_management/${id}/open`
        axios
          .put(url)
          .then((response) => {
       
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    SucessStory(ctx,{id}) {
      return new Promise((resolve, reject) => {
       
      let url=`/api/v1/service_management/${id}/toggle_success_story`
        axios
          .put(url)
          .then((response) => {
          
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    
  },
};
