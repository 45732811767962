<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="row mt-2 mb-2 m-2">
        <!-- Table Top -->
        <b-col
            cols="6"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
             <b-button variant="primary" @click="$router.push({ name: 'add-complain-benficiary' })">
                <span class="text-nowrap">إضافة شكوى</span>
              </b-button>
            
          </b-col> 
         
        <b-col
          cols="6"
          md="6"
          class="d-flex align-items-end justify-content-end mb-1 mb-md-0"
        >
          <label>Show</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
       

        </b-col>

        

    
      </div>

      <b-table
        ref="refproblemListTable"
        class="position-relative"
        :items="fetchListProblems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: User -->

        <!-- Column: Role -->

        <!-- Column: Status -->
        <!-- Column: Status -->

        <!-- Column: Actions -->
      <!-- Column: Status -->
      <template #cell(type)="data">
         <span  v-if="data.item.type==0">
          شكوى          </span>
          <span  v-else>
         مراجعة
          </span>
        </template>
      <template #cell(person_id)="data">
         <span  v-if="data.item.person_id">
            {{ data.item.person_id}}
          </span>
          <span  v-else>
            {{ data.item.custom_name}}
          </span>
        </template>
      <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status}}
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item 
            :to="{ name: 'reply-complains', params: { id: data.item.id } }"
              
            >
              <feather-icon icon="CheckSquareIcon" />
                <span class="align-middle ml-50">رد على الشكوى</span>
            </b-dropdown-item>
            <b-dropdown-item
             
               :to="{ name: 'update-complains', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50"> استكمال الشكوى</span>
            </b-dropdown-item>

       
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,

  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BPagination,
  BCardHeader,
  BCardBody,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { ref, onUnmounted } from "@vue/composition-api";


import useProblemsList from "./useProblemsList";
import { max } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import problemsStoreModule from "./problemsStoreModule";
export default {
  setup() {
    const Problems_APP_STORE_MODULE_NAME = "app-problems";

    // Register module
    if (!store.hasModule(Problems_APP_STORE_MODULE_NAME ))
      store.registerModule(Problems_APP_STORE_MODULE_NAME , problemsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(Problems_APP_STORE_MODULE_NAME ))
        store.unregisterModule(Problems_APP_STORE_MODULE_NAME );
    });


    //  const SubService = () => {
    //   store.dispatch("app-services/SubService",serviceId).then((response) => {
    //     subServiceOption.value = response.data.data;
    //     //console.log(subServiceOption)
    //   });
    // };

    const {
      fetchListProblems,
      tableColumns,

      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      reftransferListTable,
      refetchData,
      refproblemListTable,
      resolveUserStatusVariant,


    } = useProblemsList();

    return {
      resolveUserStatusVariant,
       
      fetchListProblems,
      refproblemListTable,
      tableColumns,
 
      perPage,
      currentPage,
 
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      reftransferListTable,
      refetchData,
    };
  },
  components: {

    flatPickr,
    BButton,
    max,
    BDropdownItemButton,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormInput,

    BTable,
    BMedia,
 
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardHeader,
    BCardBody,

    vSelect,
  },
methods:{
   
}

};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
<style scoped>
div {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.media {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>